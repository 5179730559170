<template>
  <v-list-item @click="$emit('selectUpsellGroup', upsellGroup)">
    <v-list-item-content>
      <v-list-item-title class="d-flex justify-space-between align-center">
        <span>{{ upsellGroup.label }}</span>
        <v-chip
          x-small
          :color="upsellGroup.status ? 'success' : 'grey lighten-1'"
        >
          {{ upsellGroup.status ? $t('active') : $t('inactive') }}
        </v-chip>
      </v-list-item-title>
      <v-list-item-subtitle 
        v-if="upsellGroup.description" 
        class="mt-1 text-truncate"
      >
        {{ upsellGroup.description }}
      </v-list-item-subtitle>
      <v-list-item-subtitle class="mt-1 text--disabled">
        <span v-if="upsellGroup.category">
          <v-icon x-small>mdi-folder-outline</v-icon>
          {{ upsellGroup.category.name }}
        </span>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'UpsellGroupListItem',
  props: {
    upsellGroup: {
      type: Object,
      required: true
    }
  }
}
</script>